import { message } from "antd";
import instance from "../../../config/axios.config";


export const submitData = async (id: number | string | undefined, values: any) => {

    // const formdata = new FormData();

    // for (const key in values) {
    //     if (key === 'image') {
    //         formdata.append(key, values[key]);
    //     } else {
    //         formdata.append(key, values[key]);
    //     }
    // }

    const url = id ? `/admin/${id}` : `/admin/create`
    const response = await instance({ url, method: id ? "PATCH" : "POST", data: values });

    return response.data;
}

export const finalExamStatusCheck = async (
    id: number | string | undefined,
    type: "received" | "paid" | "sent"
) => {
    let status = "received";

    if (type === "received") status = "paid"
    if (type === "paid") status = "sent"
    if (type === "sent") return message.warning("Buyurtma holati yakunlangan")


    const url = `order/status/${id}`;

    const response = await instance({ url, method: "PATCH", data: { status } });

    return response.data;
};