
import { useEffect } from "react";
import RoutesMiddleware from "./routes/routes_middleware";
import { useAppDispatch } from "./store/service";
import { signIn } from "./store/auth";

function App() {
  const dispatch: any = useAppDispatch();
  let isMobile = /iPhone|Android/i.test(navigator.userAgent);

  useEffect(() => {
    const isHasToken = localStorage.getItem("access_token");
    if(isHasToken) {
      dispatch(signIn({}));
    }
  }, [])

  if(isMobile) {
    return <div className="text-center py-4 pt-12" >
      <h1 className="text-red-600 " >Mobile qurilmadan kirish imkoni yo'q!!!</h1>
      <h1 className="text-yellow-500 mt-12" >Iltimos kompyuter yoki notebookingizdan kiring</h1>
    </div>
  }

  return (
    <RoutesMiddleware />
  );
}

export default App;
