import { message } from "antd";
import instance from "../../../config/axios.config";


export const submitData = async (id: number | string | undefined, values: any, img: any) => {

    const url = id ? `/product/${id}` : `/product/create`
    const response = await instance({ url, method: id ? "PATCH" : "POST", data: { ...values, image: img} });

    return response.data;
}

export const sendImage = async (file: any, id?: string) => {

    if (!file && !id){
        message.warning("Faylni yuklang!!!")
        return
    }
    const data = new FormData();
    data.append("file", file)
    const response = await instance({ url: id ? `file/${id}` : 'file/upload', method: id ? "delete" : "POST", data });

    return response.data;
}