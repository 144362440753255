import { Form, Input, Button } from 'antd'
import { useAppDispatch } from '../../store/service';
import { signIn } from '../../store/auth';

const Login = () => {

    const [form] = Form.useForm();
    const dispatch: any = useAppDispatch();

    const onFinish = (values: { username: string, password: string }) => {
        dispatch(signIn(values))
    }

    return (
        <div className="">
            <div className="grid grid-cols-12 h-[100vh] max-md:bg-[#c6c6c6] text-center">
                <div className="xl:col-span-5 md:col-span-6 col-span-12 m-auto">
                    <div className='max-md:py-4 max-md:px-5 rounded-md bg-white'>
                        <p className='text-[30px] font-bold mb-4'>Husnixat.uz <br /></p>
                        <Form
                            form={form}
                            layout="vertical"
                            autoComplete="off"
                            className='lg:w-[400px] md:w-[350px] w-[100%]'
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="Phone number"
                                name="phoneNumber"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Button type="primary" className='w-[100%]' htmlType='submit'>Submit</Button>
                        </Form>
                    </div>
                </div>
                <div className="xl:col-span-7 md:col-span-6 col-span-12 max-md:hidden bg-no-repeat bg-cover login-bg"></div>
            </div>
        </div>
    )
}
export default Login;