import { useEffect, useState } from "react";
import { Button, Tabs, TabsProps } from "antd";
import PageHeader from "../../components/HeaderPage";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import UpdateData from "./crud/update";
import useUrlQueryParams from "../../cutom-hooks/useUrlQueryParams";
import ProductStatustable from "./components/productStatustable";

const Products = () => {
    const [openUpdate, setOpenUpdate] = useState<boolean>(false);
    const [localData, setData] = useState([]);

    const [selectedItem, setselectedItem] = useState<any>();
    const { urlValue, writeToUrl } = useUrlQueryParams({ currentPage: 1, perPage: 15 });


    const { data, refetch, isFetching: isPending, isSuccess } = useGetAllData({
        queryKey: ["products", urlValue.currentPage, urlValue.perPage, urlValue.filter_like?.type],
        url: "product/all",
        urlParams: {
            page: urlValue.currentPage,
            limit: urlValue.perPage,
            type: urlValue.filter_like?.type !== "all" ? urlValue.filter_like?.type : "",
        }

    });

    useEffect(() => {
        setData(data?.products);
    }, [isSuccess])

    const items: TabsProps['items'] = [
        {
            key: 'all',
            label: <span className="text-gray-900">Barchasi</span>,
            children: <ProductStatustable data={data ?? { orders: localData, total: localData?.length }} refetch={refetch} isPending={isPending} />,
        },
        {
            key: 'usual',
            label: <span className="text-blue-600">Odatiy</span>,
            children: <ProductStatustable data={data ?? { orders: localData, total: localData?.length }} refetch={refetch} isPending={isPending} />,
        },
        {
            key: 'special',
            label: <span className="text-yellow-600">Maxsus</span>,
            children: <ProductStatustable data={data ?? { orders: localData, total: localData?.length }} refetch={refetch} isPending={isPending} />,
        },
    ];

    return (
        <div>
            <PageHeader
                breadCrumbData={[
                    { name: "Asosiy sahifa", path: '/' },
                    { name: "Maxsulotlar", path: '/products' }
                ]}
                title={"Maxsulotlar"}
                btn={
                    <Button onClick={() => { setOpenUpdate(true); setselectedItem(undefined) }} type="primary">Qo'shish</Button>
                }
            />
            <div className="bg-white p-4 pt-0 rounded-lg">
                <Tabs defaultActiveKey="1" items={items} onChange={(e) => { writeToUrl({ name: "type", value: e }); writeToUrl({ name: "currentPage", value: 1 }) }} />
            </div>
            <UpdateData open={openUpdate} setOpen={setOpenUpdate} refetch={refetch} selectedItem={selectedItem} setselectedItem={setselectedItem} />
        </div>
    )
}

export default Products;