import { useEffect, useState } from "react";
import { Button, Table } from "antd";
import type { ColumnsType } from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import Actions from "../../components/Actions";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import UpdateData from "./crud/update";
import { number_order } from "../../utils/number_orders";
import useUrlQueryParams from "../../cutom-hooks/useUrlQueryParams";
import CustomPagination from "../../components/Pagination";
import VieWData from "./crud/view";
import { formatDate } from "../../utils/functions";

const Users = () => {
    const [openUpdate, setOpenUpdate] = useState<boolean>(false);
    const [openView, setOpenView] = useState<boolean>(false);
    const [localData, setData] = useState([]);

    const [selectedItem, setselectedItem] = useState<any>();
    const { urlValue } = useUrlQueryParams({ currentPage: 1, perPage: 15 });

    const columns: ColumnsType<any> = [
        {
            title: '№',
            dataIndex: 'order',
            render: (_, __, i) => number_order(urlValue.currentPage, urlValue.perPage, Number(i), isPending),
            width: 45,
        },
        {
            title: 'F.I.O',
            render: (e) => <span>{e?.tgLastName} {e?.tgFirstName}</span>,
            key: 'name',
        },
        {
            title: 'User id',
            dataIndex: 'userId',
            key: 'username',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            render: (e) => <div>{e || <span className="text-red-600" >Mavjud emas</span>}</div>,
            key: 'username',
        },
        {
            title: 'Foydalanishni boshlagan vaqti',
            dataIndex: 'createdAt',
            render: (e) => <>{formatDate(e)}</>,
            key: 'role',
        },
        {
            title: 'Action',
            key: 'action',
            width: 120,
            align: "center",
            render: (_, record) => <Actions
                id={record?._id}
                url={'products'}
                refetch={refetch}
                isdelete={false}
                isEdit={false}
                onClickEdit={() => { }}
                onClickView={() => { if (record?.username) window.open(`https://t.me/${record?.username}`, "_blank") }}
            />,
        },
    ];

    const { data, refetch, isFetching: isPending, isSuccess } = useGetAllData({
        queryKey: ["users", urlValue.currentPage, urlValue.perPage],
        url: "user/all",
        urlParams: {
            page: urlValue.currentPage,
            limit: urlValue.perPage
        },
    });

    useEffect(() => {
        setData(data?.users)
    }, [isSuccess])

    return (
        <div>
            <PageHeader
                breadCrumbData={[
                    { name: "Asosiy sahifa", path: '/' },
                    { name: "Foydalanuvchilar", path: '/user' }
                ]}
                title={"Foydalanuvchilar"}
            />
            <Table size="small" columns={columns} dataSource={data ? data?.users : localData} loading={isPending} pagination={false} />

            {(data?.total ?? 0) > 10 ? <CustomPagination totalCount={data?.total} currentPage={urlValue.currentPage} perPage={urlValue.perPage} /> : undefined}

            <VieWData open={openView} setOpen={setOpenView} selectedItem={selectedItem} setselectedItem={setselectedItem} />
            <UpdateData open={openUpdate} setOpen={setOpenUpdate} refetch={refetch} selectedItem={selectedItem} setselectedItem={setselectedItem} />
        </div>
    )
}

export default Users;