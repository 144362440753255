import { ReactElement } from "react"
import { Layout, theme } from 'antd';
import CSidebar from "./Sidebar";
import HeaderDropdown from "../HeaderDropdown";
const { Header, Content, Footer, Sider } = Layout;

type PropsTypeCLayout = {
    children: ReactElement
}

const AdminLayout = ({ children }: PropsTypeCLayout) => {

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: "#F9FAFB" }}>
            <CSidebar />
            <Layout>
                <Header style={{ padding: 0, background: colorBgContainer }}>
                    <div className="flex justify-end pr-5 pt-3">
                        <HeaderDropdown />
                    </div>
                </Header>
                <Content style={{ margin: '16px' }}>
                    <div style={{ padding: 15, minHeight: '80vh', }}>
                        {children}
                    </div>
                </Content>
                {/* <Footer style={{ textAlign: 'center' }}>©2023</Footer> */}
            </Layout>
        </Layout>
    )
}
export default AdminLayout