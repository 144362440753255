import { useEffect, useState } from "react";
import { Button, Table } from "antd";
import type { ColumnsType } from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import Actions from "../../components/Actions";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import UpdateData from "./crud/update";
import { number_order } from "../../utils/number_orders";
import useUrlQueryParams from "../../cutom-hooks/useUrlQueryParams";
import CustomPagination from "../../components/Pagination";
import VieWData from "./crud/view";

const Admins = () => {
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [openView, setOpenView] = useState<boolean>(false);
  const [localData, setData] = useState([]);

  const [selectedItem, setselectedItem] = useState<any>();
  const { urlValue } = useUrlQueryParams({ currentPage: 1, perPage: 15 });

  const columns: ColumnsType<any> = [
    {
      title: '№',
      dataIndex: 'order',
      render: (_, __, i) => number_order(urlValue.currentPage, urlValue.perPage, Number(i), isPending),
      width: 45,
    },
    {
      title: 'F.I.O',
      render: (e) => <span>{e?.lastName} {e?.firstName}</span>,
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'username',
    },
    {
      title: 'Telefon raqami',
      dataIndex: 'phoneNumber',
      key: 'username',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Action',
      key: 'action',
      width: 120,
      align: "center",
      render: (_, record) => <Actions
        id={record?._id}
        url={'admin'}
        refetch={refetch}
        onClickEdit={() => { setOpenUpdate(true); setselectedItem(record) }}
        onClickView={() => { setOpenView(true); setselectedItem(record) }}
      />,
    },
  ];


  const { data, refetch, isFetching: isPending, isSuccess } = useGetAllData({
    queryKey: ["admins"],
    url: "admin/all",
    urlParams: {
      page: urlValue.currentPage,
      limit: urlValue.perPage
    }
  });

  useEffect(() => {
    setData(data?.admins)
  }, [isSuccess])

  return (
    <div>
      <PageHeader
        breadCrumbData={[
          { name: "Asosiy sahifa", path: '/' },
          { name: "Adminlar", path: '/admins' }
        ]}
        title={"Adminlar"}
        btn={
          <Button onClick={() => { setOpenUpdate(true); setselectedItem(undefined) }} type="primary">Qo'shish</Button>
        }
      />
      <Table size="small" columns={columns} dataSource={data ? data?.admins : localData} loading={isPending} pagination={false} />

      {(data?.total ?? 0) > 10 ? <CustomPagination totalCount={data?.total} currentPage={urlValue.currentPage} perPage={urlValue.perPage} /> : undefined}

      <VieWData open={openView} setOpen={setOpenView} selectedItem={selectedItem} setselectedItem={setselectedItem} />
      <UpdateData open={openUpdate} setOpen={setOpenUpdate} refetch={refetch} selectedItem={selectedItem} setselectedItem={setselectedItem} />
    </div>
  )
}

export default Admins;