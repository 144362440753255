import { ThemeConfig } from "antd"


export const antdCustomTokens = ():ThemeConfig => {

    return {
        token: {
            // colorPrimary: "#007A5E",
            colorPrimary: "#EAB308",
            // colorPrimary: "#ca8a04",
            colorText: "#002248",
            colorTextBase: "rgba(0, 0, 0, 1)",
            colorBgBase: "#fff",
            // lightSiderBg: "#f5f5f5",
            // colorBgLayout: "white",
            // colorBgContainer: "white",
            // colorBgSpotlight: "white",
            // colorBgElevated: "red",
            // colorBgMask: "red"
        },
        // components: {
        //     Select: {
        //         optionActiveBg: 'red'
        //     },
        
        // }
    }
}