import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import { RoutesTypeElement } from "./types";
import { HiOutlineHome } from "react-icons/hi";
import Products from "../pages/products";
import Users from "../pages/users";
import Admins from "../pages/admins";
import {
  FaHome,
  FaShoppingCart,
  FaStore,
  FaUsers,
  FaUsersCog,
} from "react-icons/fa";
import Orders from "../pages/orders";

export const public_routes: RoutesTypeElement[] = [
  {
    name: "Login",
    path: "/signin",
    component: Login,
    config: {
      key: "unlock",
      icon: HiOutlineHome,
      structure: "nonlayout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
];

export const protected_routes: RoutesTypeElement[] = [
  {
    name: "Asosiy sahifa",
    path: "/",
    component: Dashboard,
    config: {
      key: "unlock",
      icon: FaHome,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },

  // Products
  {
    name: "Maxsulotlar",
    path: "/product",
    component: Products,
    config: {
      key: "unlock",
      icon: FaStore,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },

  // Orders
  {
    name: "Buyurtmalar",
    path: "/order",
    component: Orders,
    config: {
      key: "unlock",
      icon: FaShoppingCart,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },

  // Users
  {
    name: "Foydalanuvchilar",
    path: "/users",
    component: Users,
    config: {
      key: "unlock",
      icon: FaUsers,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  // Admins
  {
    name: "Adminlar",
    path: "/admins",
    component: Admins,
    config: {
      key: "unlock",
      icon: FaUsersCog,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
];
