import { FC, useEffect, useState } from "react";
import { Button, Popover, Switch, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import useUrlQueryParams from "../../../cutom-hooks/useUrlQueryParams";
import CustomPagination from "../../../components/Pagination";
import VieWData from "../crud/view";
import { number_order } from "../../../utils/number_orders";
import divideLargeNumber from "../../../utils/devide_large_number";
import { formatDate } from "../../../utils/functions";
import Actions from "../../../components/Actions";

export const renderOrderStatus = (status: string, className?: string) => {
  if (status === "received")
    return (
      <span
        className={`text-orange-500 bg-orange-100 p-[2px] px-3 rounded-md text-md ${className} `}
      >
        Kelib tushgan
      </span>
    );
  if (status === "paid")
    return (
      <span
        className={`text-blue-600 bg-blue-100 p-[2px] px-3 rounded-md text-md ${className} `}
      >
        To'lov qilingan
      </span>
    );
  if (status === "sent")
    return (
      <span
        className={`text-green-600 bg-green-100 p-[2px] px-3 rounded-md text-md ${className} `}
      >
        Jo'natilgan
      </span>
    );
  return <></>;
};

const OrderStatustable: FC<{
  data: { orders: any[]; total: number };
  mutate: any;
  isPending: boolean;
  refetch: () => void;
}> = ({ data, mutate, isPending, refetch }): JSX.Element => {
  const [openView, setOpenView] = useState<boolean>(false);

  const [selectedItem, setselectedItem] = useState<any>();
  const { urlValue } = useUrlQueryParams({});

  const columns: ColumnsType<any> = [
    {
      title: "№",
      dataIndex: "order",
      render: (_, __, i) =>
        number_order(
          urlValue.currentPage,
          urlValue.perPage,
          Number(i),
          isPending
        ),
      width: 45,
    },
    {
      title: "Buyurtmachi",
      render: (e) => (
        <span>
          {e?.user?.tgLastName} {e?.user?.tgFirstName}
        </span>
      ),
      key: "name",
    },
    {
      title: "Qabul qiluvchi",
      render: (e) => <span>{e?.shippingInfo?.fullName}</span>,
      key: "name",
    },
    // {
    //   title: 'Kitoblar',
    //   dataIndex: 'products',
    //   key: 'username',
    //   render: (products) => {
    //     return products?.map((e: any) => (
    //       <div className=""><span>{e?.product?.name}: </span>&nbsp;<b>{e?.quantity}</b> ta</div>
    //     ))
    //     return <></>
    //   }
    // },
    {
      title: "Narxi",
      dataIndex: "totalPrice",
      key: "username",
      render: (e) => <span>{divideLargeNumber(e) ?? 0} so'm</span>,
    },
    // {
    //   title: 'Comment',
    //   dataIndex: 'comment',
    //   key: 'role',
    // },
    {
      title: "Telefon raqam",
      render: (e) => <span>{e?.shippingInfo?.phoneNumber}</span>,
      key: "phone_number",
    },

    {
      title: "Viloyat",
      render: (e) => <span>{e?.shippingInfo?.region}</span>,
      key: "region",
    },
    {
      title: "Buyurtma qilingan vaqt",
      dataIndex: "createdAt",
      render: (e) => <>{formatDate(e)}</>,
      key: "role",
    },
    {
      title: "Holati",
      fixed: "right",
      width: 190,
      align: "center",
      render: (e) => {
        return (
          <>
            <div className="">{renderOrderStatus(e?.status)}</div>
            {/* <Popover content={
              <div className='flex flex-col gap-2' >
                <div className="flex-between">
                  <span>Kelib tushgan:</span>&nbsp;&nbsp;
                  <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={e?.status === "received" || e?.status === "paid" || e?.status === "sent"} onChange={(a) => mutate({ id: e?._id, status: a ? 1 : 0, type: "received" })} disabled={!((e?.status === "received"))} />
                </div>
                <div className="flex-between">
                  <span>To'lov qilingan:</span>&nbsp;&nbsp;
                  <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={e?.status === "paid" || e?.status === "sent"} onChange={(a) => mutate({ id: e?._id, status: a ? 1 : 0, type: "paid" })} disabled={!((e?.status === "paid" || e?.status === "received"))} />
                </div>
                <div className="flex-between">
                  <span>Jo'natilgan:</span>&nbsp;&nbsp;
                  <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={e?.status === "sent"} onChange={(a) => mutate({ id: e?._id, status: a ? 1 : 0, type: "sent" })} disabled={!((e?.status === "sent" || e?.status === "paid"))} />
                </div>
              </div>
            } title="Buyurtma holat" trigger="click" placement='bottom' >
              <Button size='small' className="flex items-center px-2" style={{ borderRadius: "0 4px 4px 0" }}><FaEllipsisV fontSize={13} /></Button>
            </Popover> */}
          </>
        );
      },
    },
    {
      title: "Amallar",
      key: "action",
      width: 90,
      align: "center",
      render: (_, record) => (
        <Actions
          id={record?._id}
          url={"order"}
          refetch={refetch}
          isEdit={false}
          onClickEdit={() => { }}
          onClickView={() => {
            setOpenView(true);
            setselectedItem(record);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <Table
        size="small"
        columns={columns}
        dataSource={data?.orders}
        loading={isPending}
        pagination={false}
      />

      {(data?.total ?? 0) > 10 ? (
        <CustomPagination
          totalCount={data?.total}
          currentPage={urlValue.currentPage}
          perPage={urlValue.perPage}
        />
      ) : undefined}
      <VieWData
        open={openView}
        setOpen={setOpenView}
        selectedItem={selectedItem}
        setselectedItem={setselectedItem}
        refetch={refetch}
      />
    </div>
  );
};

export default OrderStatustable;
