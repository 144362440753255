import divideLargeNumber from "./devide_large_number";

type TConstants = {
  textAreaLength: number;
  antdDrawerWidth: number;
  tableScroll: { x: number };
  debounsDuration: number;
  queryStaleTime: number;
  lectureIdForTimeTable: number;
};

export const globalConstants: TConstants = {
  textAreaLength: 1000,
  antdDrawerWidth: 540,
  tableScroll: { x: 768 },
  debounsDuration: 1200,
  queryStaleTime: 60000,
  lectureIdForTimeTable: 1,
};

export const lineChartOptions: any = (
  categories?: number[] | string[],
  color?: string[]
) => ({
  chart: {
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: "#4318FF",
    },
  },
  colors: color ?? ["#4318FF", "#39B8FF"],
  markers: {
    size: 0,
    colors: "white",
    strokeColors: color ? color[0] : "#7551FF",
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    theme: "light",
    blur: 6,
    opacity: 0.8,
    y: {
      formatter: function (val: any) {
        return divideLargeNumber(val) + " ta";
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    // opacity: 0.48,
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.6,
      //   stops: [0, 100],
    },
  },
  stroke: {
    curve: "smooth",
    type: "line",
    width: 3,
  },
  xaxis: {
    // type: "numeric",
    categories: categories,
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    // show: false,
    labels: {
      // style: {
      //   colors: "#A3AED0",
      //   fontSize: "12px",
      //   fontWeight: "500",
      // },
      style: {
        colors: "#c3ced8",
        fontSize: "12px",
      },
      formatter: (val: any) => {
        return divideLargeNumber(val);
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    // show: false,
    column: {
      color: ["#7551FF", "#39B8FF"],
      opacity: 0.5,
    },
  },
  color: ["#7551FF", "#39B8FF"],
});

export const barChartOptions: any = (
  categories?: number[] | string[],
  color?: string[]
) => ({
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    y: {
      formatter: function (val: any) {
        return divideLargeNumber(val) + " so'm";
      },
    },
    // theme: "dark",
  },
  xaxis: {
    categories,
    // show: false,
    labels: {
      // show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    // show: false,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#c3ced8",
        fontSize: "12px",
      },
      formatter: (val: any) => {
        return divideLargeNumber(val / 1000) + " K";
      },
    },
  },
  grid: {
    // show: false,
    strokeDashArray: 5,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      type: "vertical",
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      colorStops: [
        [
          {
            offset: 0,
            color: "#4318FF",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgba(67, 24, 255, 1)",
            opacity: 0.28,
          },
        ],
      ],
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "40px",
    },
  },
});
