import { AxiosRequestConfig } from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../config/axios.config";
import { redirect } from "react-router-dom";

export const signIn = createAsyncThunk(
  "signIn",
  async (
    params: { phoneNumber?: string; password?: string },
    { rejectWithValue }
  ) => {
    try {
      const isHasToken = localStorage.getItem("access_token");

      const options: AxiosRequestConfig = isHasToken
        ? { url: `/user/all?page=1&limit=1`, method: "GET" }
        : { url: `/admin/login`, method: "POST", data: params };

      const response = await instance(options);

      if (response?.status === 401) return redirect("/signin");

      if (!(response?.status === 201 || response?.status === 200))
        return rejectWithValue(new Error("Authorization error!"));

      if (!isHasToken) {
        localStorage.setItem("access_token", response?.data?.token);
      }

      return {};
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  }
);

export interface IAuth {
  isLoading: boolean;
  status: "pending" | "success" | "error";
  isAuthenticated: boolean;
}

export const initialStateAuth: IAuth = {
  isLoading: false,
  status: "success",
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialStateAuth,
  reducers: {
    signOut(state) {
      state.isAuthenticated = false;
      state.status = "success";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.isLoading = true;
      state.status = "pending";
    });
    builder.addCase(signIn?.fulfilled.type, (state, action) => {
      state.isAuthenticated = true;
      state.isLoading = false;
    });
    builder.addCase(signIn?.rejected.type, (state, action) => {
      redirect("/signin");
      // localStorage.removeItem("access_token");
      state = {
        ...state,
        isLoading: false,
        status: "error",
      };
    });
  },
});

export const AUTH_ACTIONS = authSlice.actions;

export default authSlice;
