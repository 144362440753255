// generate antd Col span
export const generateAntdColSpan = (
  span:
    | number
    | {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
        xl?: number;
        xxl?: number;
      }
    | undefined
) => {
  if (typeof span === "number") return { span };

  let initialSpan: any = { xs: 24, sm: 24, md: 12, lg: 8, xl: 6 };
  if (span) {
    Object.entries(span)?.forEach(([key, value]) => {
      initialSpan = { ...initialSpan, [key]: value };
    });
  }
  return initialSpan;
};

//  amtd select search
export const cf_filterOption = (input: string, option: any) => {
  return (
    String(option?.children)
      .toLowerCase()
      .indexOf(String(input).toLowerCase()) >= 0
  );
};

export function getMonthName(monthId: number | string) {
  const date = new Date();
  date.setMonth(Number(monthId) - 1);
  const monthName = date.toLocaleString("default", { month: "short" });
  return monthName;
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  return date
    .toLocaleString("uz-UZ", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    })
    .replace(/[/]/g, ".")
    .replace(",", " ");
}
