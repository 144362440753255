import { Dispatch, useEffect } from 'react'
import { Button, Drawer, Form, Input, InputNumber } from "antd";
import { useMutation } from '@tanstack/react-query';
import { Notification } from '../../../components/Notifications/notification';
import { AxiosError } from 'axios';
import { submitData } from './request';
import MultipleInput from '../../../components/MultipleInput';

const UpdateData = ({ open, setOpen, refetch, selectedItem, setselectedItem }: { open: boolean, setOpen: Dispatch<boolean>, refetch: any, selectedItem: any, setselectedItem: Dispatch<any> }) => {

    const [form] = Form.useForm();

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        form.setFieldsValue({
            lastName: selectedItem?.lastName,
            firstName: selectedItem?.firstName,
            email: selectedItem?.email,
            phoneNumber: selectedItem?.phoneNumber,
            role: selectedItem?.role,
            password: selectedItem?.password,
        })
    }, [selectedItem])

    useEffect(() => {
        if (!open) setselectedItem(undefined)
    }, [open])

    const { mutate, isPending } = useMutation({
        mutationFn: (newVals) => submitData(selectedItem?._id, newVals),
        onSuccess: async (res) => {
            Notification("success", "update", res?.message)
            refetch()
            setselectedItem(undefined)
            setOpen(false)
        },
        onError: (error: AxiosError<any>) => {
            Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });

    return (
        <div>
            <Drawer key={3} title={"Admin" + (selectedItem ? " tahrirlash" : " qo'shish")} placement="right" width={500} onClose={onClose} open={open}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={mutate}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Ismi"
                        name="firstName"
                        rules={[{ required: true, message: 'Please input name!' }]}
                    >
                        <Input placeholder="Ismi" />
                    </Form.Item>
                    <Form.Item
                        label="Familiyasi"
                        name="lastName"
                        rules={[{ required: true, message: 'Please input name!' }]}
                    >
                        <Input placeholder="Familiyasi" />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input email!' }]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        label="Telefon raqami"
                        name="phoneNumber"

                        rules={[{ required: true, message: 'Please input phoneNumber!' }]}
                    >
                        <Input placeholder="+999 99 999 99 99" className='w-full' />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input Password!' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Form.Item className='flex justify-end'>
                        <Button type="primary" htmlType="submit" loading={isPending}>
                            Saqlash
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    )
}
export default UpdateData;