import { useEffect, useState } from "react";
import { Tabs, TabsProps } from "antd";
import PageHeader from "../../components/HeaderPage";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import useUrlQueryParams from "../../cutom-hooks/useUrlQueryParams";
import { useMutation } from "@tanstack/react-query";
import { Notification } from "../../components/Notifications/notification";
import { finalExamStatusCheck } from "./crud/request";
import OrderStatustable from "./components/orderStatustable";

export const renderOrderStatus = (status: string, className?: string) => {
  if (status === "received") return <span className={`text-orange-500 bg-orange-100 p-[2px] px-3 rounded-md text-md ${className} `} >Kelib tushgan</span>
  if (status === "paid") return <span className={`text-blue-600 bg-blue-100 p-[2px] px-3 rounded-md text-md ${className} `} >To'lov qilingan</span>
  if (status === "sent") return <span className={`text-green-600 bg-green-100 p-[2px] px-3 rounded-md text-md ${className} `} >Jo'natilgan</span>
  return <></>
}


const Orders = () => {
  const [localData, setData] = useState([]);
  const { urlValue, writeToUrl } = useUrlQueryParams({ currentPage: 1, perPage: 15 });

  const { data, refetch, isFetching: isPending, isSuccess } = useGetAllData({
    queryKey: ["order", urlValue.filter_like?.status, urlValue.currentPage, urlValue.perPage],
    url: "order/all",
    urlParams: {
      status: urlValue.filter_like?.status !== "all" ? urlValue.filter_like?.status : "",
      page: urlValue.currentPage,
      limit: urlValue.perPage
    }
  });

  useEffect(() => {
    setData(data?.orders)
  }, [isSuccess])

  const { mutate, isPending: click } = useMutation({
    mutationFn: ({ id, status }: { id: number | undefined, status: "received" | "paid" | "sent" }) => finalExamStatusCheck(id, status),
    onSuccess: async (res) => {
      Notification("success", "update", "Ma'lumot o'zgartirildi");
      refetch()
    },
    onError: (error: any) => {
      Notification("error", "update", error?.response ? error?.response?.message : "");
    },
    retry: 0,
  });

  const items: TabsProps['items'] = [
    {
      key: 'all',
      label: <span className="text-gray-800">Barchasi</span>,
      children: <OrderStatustable data={data ?? { orders: localData, total: localData?.length }} refetch={refetch} isPending={isPending} mutate={mutate} />,
    },
    {
      key: 'received',
      label: <span className="text-yellow-500">Kelib tushgan</span>,
      children: <OrderStatustable data={data ?? { orders: localData, total: localData?.length }} refetch={refetch} isPending={isPending} mutate={mutate} />,
    },
    {
      key: 'paid',
      label: <span className="text-blue-600">To'langan</span>,
      children: <OrderStatustable data={data ?? { orders: localData, total: localData?.length }} refetch={refetch} isPending={isPending} mutate={mutate} />,
    },
    {
      key: 'sent',
      label: <span className="text-green-600">Jo'natilagn</span>,
      children: <OrderStatustable data={data ?? { orders: localData, total: localData?.length }} refetch={refetch} isPending={isPending} mutate={mutate} />,
    },
  ];

  return (
    <div>
      <PageHeader
        breadCrumbData={[
          { name: "Asosiy sahifa", path: '/' },
          { name: "Buyurtmalar", path: '/orders' }
        ]}
        title={"Buyurtmalar"}
      />

      <div className="bg-white p-4 pt-0 rounded-lg">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={(e) => {
            writeToUrl({ name: "status", value: e });
            writeToUrl({ name: "currentPage", value: 1 });
          }}
        />
      </div>
    </div>
  )
}

export default Orders;