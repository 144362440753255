import instance from "../../../config/axios.config";


export const submitData = async (id: number | string | undefined, values: any) => {

    // const formdata = new FormData();

    // for (const key in values) {
    //     if (key === 'image') {
    //         formdata.append(key, values[key]);
    //     } else {
    //         formdata.append(key, values[key]);
    //     }
    // }

    const url = id ? `/admin/${id}` : `/admin/create`
    const response = await instance({ url, method: id ? "PATCH" : "POST", data: values });

    return response.data;
}