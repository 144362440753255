import { FC, useEffect, useState } from "react";
import { Button, Popover, Switch, Table } from "antd";
import type { ColumnsType } from 'antd/es/table';
import useUrlQueryParams from "../../../cutom-hooks/useUrlQueryParams";
import CustomPagination from "../../../components/Pagination";
import VieWData from "../crud/view";
import { number_order } from "../../../utils/number_orders";
import divideLargeNumber from "../../../utils/devide_large_number";
import { formatDate } from "../../../utils/functions";
import Actions from "../../../components/Actions";
import { FILE_URL } from "../../../config/utils";
import UpdateData from "../crud/update";

export const renderProductStatus = (status: string, className?: string) => {
  if (status === "special") return <span className={`text-orange-500 bg-orange-100 p-[2px] px-3 rounded-md text-md ${className} `} >Maxsus</span>
  if (status === "usual") return <span className={`text-blue-600 bg-blue-100 p-[2px] px-3 rounded-md text-md ${className} `} >Odatiy</span>
  return <></>
}

const ProductStatustable: FC<{ data: { products: any[], total: number }, isPending: boolean, refetch: () => void }> = ({ data, isPending, refetch }): JSX.Element => {
  const [openView, setOpenView] = useState<boolean>(false);
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);

  const [selectedItem, setselectedItem] = useState<any>();
  const { urlValue } = useUrlQueryParams({});

  const columns: ColumnsType<any> = [
    {
      title: '№',
      dataIndex: 'order',
      render: (_, __, i) => number_order(urlValue.currentPage, urlValue.perPage, Number(i), isPending),
      width: 45,
    },
    {
      title: 'Rasmi',
      dataIndex: 'image',
      key: 'name',
      render: (e) => <div><img src={FILE_URL + e} alt="" className="w-[60px] rounded-lg" /></div>
    },
    {
      title: 'Nomi',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Narxi',
      dataIndex: 'price',
      render: (e) => <span>{divideLargeNumber(e) ?? 0} so'm</span>,
      key: 'price',
    },
    {
      title: 'Turi',
      dataIndex: 'type',
      render: (e) => renderProductStatus(e),
      key: 'price',
    },
    {
      title: 'Action',
      key: 'action',
      width: 120,
      align: "center",
      render: (_, record) => <Actions
        id={record?._id}
        url={'product'}
        refetch={refetch}
        onClickEdit={() => { setOpenUpdate(true); setselectedItem(record) }}
        onClickView={() => { setOpenView(true); setselectedItem(record) }}
      />,
    },
  ];

  return (
    <div>
      <Table size="small" columns={columns} dataSource={data?.products} loading={isPending} pagination={false} />

      {(data?.total ?? 0) > 10 ? <CustomPagination totalCount={data?.total} currentPage={urlValue.currentPage} perPage={urlValue.perPage} /> : undefined}
      <VieWData open={openView} setOpen={setOpenView} selectedItem={selectedItem} setselectedItem={setselectedItem} />
      <UpdateData open={openUpdate} setOpen={setOpenUpdate} refetch={refetch} selectedItem={selectedItem} setselectedItem={setselectedItem} />
    </div>
  )
}

export default ProductStatustable;