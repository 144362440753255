import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/styles/style.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { BrowserRouter } from "react-router-dom";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { Provider } from 'react-redux';
import store from './store';
import { ConfigProvider } from 'antd';
import { antdCustomTokens } from './static_data/antd_tokens';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ConfigProvider theme={antdCustomTokens()} >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </QueryClientProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// /*  chart style */

// .chart-wrapper {
//   .apexcharts-canvas {
//     .apexcharts-canvas {
//       Tooltip
//       & .apexcharts-tooltip {
//         opacity: 0.8;
//         backdrop-filter: blur(6);
//         filter: blur(5px);
//         color: theme.palette.text.primary,
//         boxShadow: 0 0 2px 0 alpha(#9e9e9e, 0.24), -20px 20px 40px -4px alpha(#9e9e9e, 0.24);
//         borderRadius: 1.25,
//         '&.apexcharts-theme-light': {
//           borderColor: 'transparent',
//           ...bgBlur({
//             color: theme.palette.background.default,
//           }),
//         }
//       }
//       '& .apexcharts-xaxistooltip': {
//         ...bgBlur({
//           color: theme.palette.background.default,
//         }),
//         borderColor: 'transparent',
//         color: theme.palette.text.primary,
//         boxShadow: theme.customShadows.dropdown,
//         borderRadius: theme.shape.borderRadius * 1.25,
//         '&:before': {
//           borderBottomColor: alpha(theme.palette.grey[500], 0.24),
//         },
//         '&:after': {
//           borderBottomColor: alpha(theme.palette.background.default, 0.8),
//         },
//       },
//       '& .apexcharts-tooltip-title': {
//         textAlign: 'center',
//         fontWeight: theme.typography.fontWeightBold,
//         backgroundColor: alpha(theme.palette.grey[500], 0.08),
//         color: theme.palette.text[theme.palette.mode === 'light' ? 'secondary' : 'primary'],
//       },

//       LEGEND
//       '& .apexcharts-legend': {
//         padding: 0,
//       },
//       '& .apexcharts-legend-series': {
//         display: 'inline-flex !important',
//         alignItems: 'center',
//       },
//       '& .apexcharts-legend-marker': {
//         marginRight: 8,
//       },
//       '& .apexcharts-legend-text': {
//         lineHeight: '18px',
//         textTransform: 'capitalize',
//       },
//     }
//   }
// }