import { Dispatch, useEffect } from "react";
import { Button, Divider, Drawer, List } from "antd";
import { renderOrderStatus } from "..";
import { FILE_URL } from "../../../config/utils";
import divideLargeNumber from "../../../utils/devide_large_number";
import { Notification } from "../../../components/Notifications/notification";
import { useMutation } from "@tanstack/react-query";
import { finalExamStatusCheck } from "./request";

const VieWData = ({
  open,
  setOpen,
  selectedItem,
  setselectedItem,
  refetch,
}: {
  open: boolean;
  setOpen: Dispatch<boolean>;
  selectedItem: any;
  setselectedItem: Dispatch<any>;
  refetch: () => void;
}) => {
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) setselectedItem(undefined);
  }, [open]);

  const { mutate, isPending: click } = useMutation({
    mutationFn: ({
      id,
      status,
    }: {
      id: number | undefined;
      status: "received" | "paid" | "sent";
    }) => finalExamStatusCheck(id, status),
    onSuccess: async (res) => {
      Notification("success", "update", "Ma'lumot o'zgartirildi");
      refetch();
      setselectedItem(undefined);
      setOpen(false);
    },
    onError: (error: any) => {
      Notification(
        "error",
        "update",
        error?.response ? error?.response?.message : ""
      );
    },
    retry: 0,
  });

  return (
    <div>
      <Drawer
        key={3}
        title={
          <div className="flex items-center justify-between">
            Buyurtmani ko'rish
            <p>{renderOrderStatus(selectedItem?.status, "text-sm")}</p>
          </div>
        }
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
      >
        <div className="flex flex-col gap-2">
          <b className="opacity-60">Buyurtma beruvchi ma'lumotlari</b>
          <div className="flex justify-between">
            <p>Ismi:</p>
            <p>
              {selectedItem?.user?.tgLastName} {selectedItem?.user?.tgFirstName}
            </p>
          </div>
          <div className="flex justify-between">
            <p>Username:</p>
            <p>
              {selectedItem?.user?.username ? (
                <Button
                  type="link"
                  onClick={() => {
                    window.open(
                      `https://t.me/${selectedItem?.user?.username}`,
                      "_blank"
                    );
                  }}
                >
                  {selectedItem?.user?.username}
                </Button>
              ) : (
                <span className="text-red-600">Mavjud emas</span>
              )}
            </p>
          </div>
          <div className="flex justify-between">
            <p>Izoh:</p>
            <p className="max-w-[70%]">{selectedItem?.comment}</p>
          </div>
          <Divider className="my-2 py-0" />
          <div className="flex flex-col gap-2">
            <b className="opacity-60">Buyurtmalar</b>
            <List
              itemLayout="horizontal"
              size="small"
              dataSource={selectedItem?.products}
              renderItem={(e: any, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <img
                        src={FILE_URL + e?.product?.image}
                        alt=""
                        className="w-12 rounded-lg"
                      />
                    }
                    title={<span>{e?.product?.name}</span>}
                    // description={e?.product?.price + " so'm"}
                    description={e?.quantity + " ta"}
                  />
                  <div className="text-md">
                    <b>{divideLargeNumber(e?.product?.price * e?.quantity)}</b>{" "}
                    so'm
                  </div>
                </List.Item>
              )}
            />
          </div>
          <Divider className="my-2 py-0" />
          <div className="flex items-center justify-between">
            <b className="opacity-60">Umumiy narxi:</b>
            <span className="text-lg">
              <b>{divideLargeNumber(selectedItem?.totalPrice)}</b> so'm
            </span>
          </div>
          <Divider className="my-2 py-0" />
          <div className="flex flex-col gap-2">
            <b className="opacity-60">Qabul qiluvchi ma'lumotlari</b>
            <div className="flex justify-between">
              <p className="opacity-60">To'liq ismi:</p>
              <p>{selectedItem?.shippingInfo?.fullName}</p>
            </div>
            <div className="flex justify-between">
              <p className="opacity-60">Telefon raqami:</p>
              <p>{selectedItem?.shippingInfo?.phoneNumber}</p>
            </div>
            <div className="flex justify-between">
              <p className="opacity-60">Viloyat:</p>
              <p>{selectedItem?.shippingInfo?.region}</p>
            </div>
            <div className="flex justify-between">
              <p className="opacity-60">Tuman:</p>
              <p>{selectedItem?.shippingInfo?.district}</p>
            </div>
            <div className="flex justify-between">
              <p className="opacity-60">Manzil:</p>
              <p>{selectedItem?.shippingInfo?.address}</p>
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex items-center justify-between mt-4">
          {(() => {
            const status = selectedItem?.status;

            if (status === "received")
              return (
                <>
                  <p className="opacity-60">To'lovni tasdiqlash:</p>
                  <Button
                    loading={click}
                    type="primary"
                    onClick={() => {
                      mutate({ id: selectedItem?._id, status });
                    }}
                  >
                    To'landi
                  </Button>
                </>
              );
            if (status === "paid")
              return (
                <>
                  <p className="opacity-60">
                    Buyurtma jo'natilganini tasdiqlash:
                  </p>
                  <Button
                    loading={click}
                    type="primary"
                    onClick={() => {
                      mutate({ id: selectedItem?._id, status });
                    }}
                  >
                    Jo'natildi
                  </Button>
                </>
              );
            return <></>;
          })()}
        </div>
      </Drawer>
    </div>
  );
};
export default VieWData;
