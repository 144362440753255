import { Spin } from 'antd';
import { FaBook, FaShoppingCart } from 'react-icons/fa';
import { PiUsersFourFill } from 'react-icons/pi';
import { BiSolidPurchaseTag } from 'react-icons/bi';
import useGetAllData from '../../cutom-hooks/useGetAllData';
import { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import { barChartOptions, lineChartOptions } from '../../utils/constants';

import img_order from "../../assets/images/ic_glass_buy.png"
import img_product from "../../assets/images/ic_glass_message.png"
import img_sale from "../../assets/images/ic_glass_bag.png"
import img_user from "../../assets/images/ic_glass_users.png"
import { getMonthName } from '../../utils/functions';
import divideLargeNumber from '../../utils/devide_large_number';



const topData = [
    {
        icon: <PiUsersFourFill className='text-sky-600 text-[35px]' />,
        title: "Foydalanuvchilar",
        name: "totalUsers",
        img: img_user
    },
    {
        icon: <FaBook className='text-sky-600 text-[35px]' />,
        title: "Maxsulotlar",
        name: "totalProducts",
        img: img_product
    },
    {
        icon: <BiSolidPurchaseTag className='text-sky-600 text-[35px]' />,
        title: "Buyurtmalar",
        name: "totalOrders",
        img: img_order
    },
    {
        icon: <FaShoppingCart className='text-sky-600 text-[35px]' />,
        title: "Sotilgan maxsulotlar",
        name: "totalSoldProducts",
        img: img_sale
    },
]

const Dashboard = () => {
    const [chartData, setData] = useState<any>({
        // series: [
        users: {
            name: "Buyurtmalar",
            data: []
        },
        orders: {
            name: "Foydalanuvchilar",
            data: []
        },
        // ],
        options: []
    });
    const date = new Date();

    const { data, isPending, isSuccess } = useGetAllData({
        queryKey: ["statistics/all"],
        url: "statistics/all",
    });

    console.log(data);

    useEffect(() => {
        const data_1: any[] = [];
        const data_2: any[] = [];
        const userOptions: any[] = [];
        const productOptions: any[] = [];
        data?.totalUsersCountByMonth?.forEach((e: any) => {
            data_1.push((e?.totalUsers))
            userOptions.push(`${e?._id?.year} ${getMonthName(e?._id?.month)}`)
        });
        data?.totalOrderPriceByYearMonth?.forEach((e: any) => {
            data_2.push((e?.totalPrice));
            productOptions.push(`${e?._id?.year} ${getMonthName(e?._id?.month)}`)
            // if (!options?.includes(`${e?._id?.year} ${getMonthName(e?._id?.month)}`)) options.push(`${e?._id?.year} ${getMonthName(e?._id?.month)}`);
        })

        setData({
            // series: [
            users: {
                name: "Buyurtmalar",
                data: data_2
            },
            orders: {
                name: "Foydalanuvchilar",
                data: data_1
            },
            // ],
            userOptions,
            productOptions
        })
    }, [isSuccess]);

    return (
        <div>
            <Spin spinning={isPending}>
                <div className="col-span-4 text-center mb-6 ">
                    <h2 className="text-2xl text-gray-500 mb-3 mt-0 p-0">Umumiy savdo:</h2>
                    <h1 className='text-5xl text-[orange]' > {divideLargeNumber(data?.totalSales ?? 0)} so'm</h1>
                </div>
                <div className="grid xl:grid-cols-4 lg:grid-cols-2 grid-cols-1 gap-6">
                    {
                        topData.map((item, index) => (
                            <div key={index} className="p-6 py-8 rounded-2xl shadow-[0px_8px_24px_#959da533] bg-white flex items-center justify-between- gap-6">
                                {/* {item.icon} */}
                                <img src={item.img} alt='asd' className='w-14' />
                                <div className="">
                                    <h1 className='text-3xl opacity-90' >{data ? divideLargeNumber(data[item.name] ?? 0) ?? 0 : null} <span> ta</span></h1>
                                    <h3 className='opacity-50 text-xl mt-2' >{item.title}</h3>
                                </div>
                                {/* {item.text} */}
                            </div>
                        ))
                    }
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 pt-6">
                    <div className="col-span-3- p-6 rounded-2xl shadow-[0px_8px_24px_#959da533] bg-white">
                        <h5 className="text-xl">Foydalanuvchilar soni</h5>
                        <ReactApexChart
                            options={lineChartOptions(chartData?.userOptions ?? [], ["#008FFB"])}
                            series={[chartData?.orders]}
                            type="area"
                            height="300"
                        />
                    </div>
                    <div className="col-span-3- p-6 rounded-2xl shadow-[0px_8px_24px_#959da533] bg-white">
                        <h5 className="text-xl">Buyurtmalar narxi</h5>
                        <ReactApexChart
                            options={barChartOptions(chartData?.productOptions ?? [], ["#F7AF1A"])}
                            series={[chartData?.users]}
                            type="bar"
                            height="300"
                        />
                    </div>
                    <div className=""></div>
                </div>
            </Spin>
        </div>
    )
}
export default Dashboard;