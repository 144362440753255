import { Dispatch, useEffect, useState } from 'react'
import { Alert, Button, Drawer, Form, Input, InputNumber, Select, message } from "antd";
import { useMutation } from '@tanstack/react-query';
import { Notification } from '../../../components/Notifications/notification';
import { AxiosError } from 'axios';
import { sendImage, submitData } from './request';
import MultipleInput from '../../../components/MultipleInput';
import { FILE_URL } from '../../../config/utils';

const UpdateData = ({ open, setOpen, refetch, selectedItem, setselectedItem }: { open: boolean, setOpen: Dispatch<boolean>, refetch: any, selectedItem: any, setselectedItem: Dispatch<any> }) => {

    const [file, setFile] = useState<any>();
    const [fetchedFile, setFetchedFile] = useState<any>();
    const [form] = Form.useForm();

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        form.setFieldsValue({
            name: selectedItem?.name,
            price: selectedItem?.price,
            type: selectedItem?.type,
        })
    }, [selectedItem])

    useEffect(() => {
        if (!open) setselectedItem(undefined)
    }, [open])

    const { mutate, isPending } = useMutation({
        mutationFn: (newVals) => submitData(selectedItem?._id, newVals, fetchedFile?.name),
        onSuccess: async (res) => {
            Notification("success", "update", res?.message)
            refetch()
            setselectedItem(undefined)
            setOpen(false)
            setFile(undefined)
            setFetchedFile(undefined)
        },
        onError: (error: AxiosError<any>) => {
            Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });
    const { mutate: _sendImage, isPending: imageLoading } = useMutation({
        mutationFn: ({ id }: { id?: string }) => sendImage(file, id),
        onSuccess: async (res) => {
            Notification("success", "update", res?.message)
            // if (res?._id) {
            setFetchedFile(fetchedFile ? undefined : res)
            setFile(undefined);
            // }
        },
        onError: (error: AxiosError<any>) => {
            Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });

    console.log(file);
    console.log(fetchedFile);

    return (
        <div>
            <Drawer
                key={3}
                open={open}
                title={"Maxsulotni" + (selectedItem ? " tahrirlash" : " qo'shish")}
                placement="right"
                width={500}
                onClose={() => {
                    if (fetchedFile) { _sendImage({ id: fetchedFile?._id }) };
                    setFile(undefined); setFetchedFile(undefined); form.resetFields(); onClose()
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={mutate}
                    autoComplete="off"
                >
                    <Alert
                        message="Mahsulot qo'shishdan avval uning rasmini yuklang"
                        type="warning"
                        closable
                        className='mb-4'
                    />
                    {selectedItem && !fetchedFile ? <img className='w-1/2 mxc-auto rounded-lg' src={FILE_URL + selectedItem?.image} alt="" /> : null}
                    <div className={`flex items-center justify-between my-2 py-3 px-2 border border-solid ${fetchedFile ? "border-green-400" : "border-blue-400"} rounded-md`}>
                        {fetchedFile ? <img className='w-2/3 rounded-lg' src={FILE_URL + fetchedFile?.name} alt="product" /> : <input type="file" onChange={(e) => { e?.target?.files ? setFile(e?.target?.files[0]) : message.warning("Fayl yuklanmadi") }} />}
                        <Button disabled={!(fetchedFile || file)} loading={imageLoading} danger={!!fetchedFile} onClick={() => { _sendImage(fetchedFile ? { id: fetchedFile?._id } : {}) }} >{fetchedFile ? "Faylni o'chirish" : "Faylni saqlash"}</Button>
                    </div>
                    <Form.Item
                        label="Nomi"
                        name="name"
                        rules={[{ required: true, message: 'Please input name!' }]}
                    >
                        <Input placeholder="Nomi" />
                    </Form.Item>
                    <Form.Item
                        label="Narxi"
                        name="price"
                        rules={[{ required: true, message: 'Please input price!' }]}
                    >
                        <InputNumber placeholder="Narxi" className='w-full' />
                    </Form.Item>
                    <Form.Item
                        label="Turi"
                        name="type"
                        rules={[{ required: true, message: 'Please input type!' }]}
                    >
                        <Select placeholder="Turni tanlang" className='w-full' >
                            <Select.Option value={"usual"} >Odatiy</Select.Option>
                            <Select.Option value={"special"} >Maxsus</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item className='flex justify-end'>
                        <Button type="primary" htmlType="submit" disabled={!fetchedFile && !selectedItem} loading={isPending}>
                            Saqlash
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    )
}
export default UpdateData;